import { Button, useModalState } from "@m/ui";

import { PageTitle } from "@atlas/components/PageTitle";

import { AddAwsAccountModal, AwsAccountsTable } from "./components";

export const AwsAccountsPage = () => {
  const addAccountModal = useModalState();

  return (
    <main className="flex flex-col gap-3 p-3">
      <PageTitle
        title="AWS Accounts"
        actions={
          <Button kind="primary" size="small" onClick={addAccountModal.open}>
            Add Account
          </Button>
        }
      />
      <AwsAccountsTable />
      {addAccountModal.isOpen && (
        <AddAwsAccountModal onClose={addAccountModal.close} />
      )}
    </main>
  );
};
