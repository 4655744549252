import clsx from "clsx";
import { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import {
  Badge,
  CopyButton,
  Dropdown,
  DropdownItem,
  FilterBar,
  FilterSelect,
  Link,
  Search,
  Table,
  TableHeader,
  useSearchTerm,
} from "@m/ui";

import { PATHS } from "@atlas/constants";

import { AwsAccount, useAwsAccounts } from "../api";

import { AwsAccountDetailsModal } from "./AwsAccountDetailsModal";

export const AwsAccountsTable = () => {
  const [query, setQuery] = useQueryParams({
    search: StringParam,
    sort: withDefault(StringParam, DEFAULT_SORT),
    status: StringParam,
  });

  const [selectedAwsAccount, setSelectedAwsAccount] =
    useState<AwsAccount | null>(null);

  const {
    data: { awsAccounts },
    pagination,
    loading,
  } = useAwsAccounts(query);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ setQuery, query });

  const handleSortChange = (sort: string) => {
    pagination.setCurrentPage(1);
    setQuery({ sort });
  };

  const handleChangeDisabledFilter = (value: string) => {
    setQuery({ status: value });
  };

  const handleClearDisabledFilter = () => {
    setQuery({ status: undefined });
  };

  const handleResetFilters = () => {
    clearSearchTerm();
    setQuery({
      search: undefined,
      sort: DEFAULT_SORT,
      status: undefined,
    });
  };

  const rows = useMemo(
    () =>
      awsAccounts.map((awsAccount) => {
        return {
          id: (
            <AccountId
              id={awsAccount.accountId}
              disabled={awsAccount.disabled}
            />
          ),
          name: (
            <AccountName
              name={awsAccount.name}
              disabled={awsAccount.disabled}
            />
          ),
          company: (
            <CompanyName
              customerId={awsAccount.company?.databaseId}
              name={awsAccount.company?.name}
              disabled={awsAccount.disabled}
            />
          ),
          org: (
            <div className={clsx({ "opacity-60": awsAccount.disabled })}>
              <AwsOrgAccountTypeBadge
                payerType={awsAccount.payerType}
                masterAccountId={awsAccount.masterAccountId}
              />
            </div>
          ),
          actions: (
            <div className="flex justify-end">
              <Dropdown direction="left">
                <DropdownItem onClick={() => setSelectedAwsAccount(awsAccount)}>
                  See Account Details
                </DropdownItem>
              </Dropdown>
            </div>
          ),
        };
      }),
    [awsAccounts]
  );

  const isFilterActive =
    query.search !== undefined ||
    query.sort !== DEFAULT_SORT ||
    query.status !== undefined;

  return (
    <>
      <FilterBar isActive={isFilterActive} onResetFilters={handleResetFilters}>
        <Search
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          placeholder="Search by name, ID, or customer"
          searchTerm={searchTerm}
        />
        <FilterSelect
          ariaLabel="Status Filter"
          disabled={loading}
          initialValue="Status"
          onChange={handleChangeDisabledFilter}
          onClear={handleClearDisabledFilter}
          options={STATUS_FILTER_OPTIONS}
          selection={query.status ?? "Status"}
          displayValue={
            STATUS_FILTER_OPTIONS.find((o) => o.id === query.status)?.label ??
            "Status"
          }
        />
      </FilterBar>
      <Table
        defaultSort={query.sort}
        emptyMessage="No AWS accounts found"
        headers={HEADERS}
        loading={loading}
        onSortChange={handleSortChange}
        rows={rows}
        {...pagination}
      />
      <AwsAccountDetailsModal
        awsAccount={selectedAwsAccount}
        onClose={() => setSelectedAwsAccount(null)}
        open={!!selectedAwsAccount}
      />
    </>
  );
};

const AccountId = ({ id, disabled }: { id: string; disabled?: boolean }) => (
  <div className="flex items-center gap-1 font-mono">
    <Link
      href={`https://${id}.signin.aws.amazon.com/console`} // todo: replace with cross-account link
      target="_blank"
      rel="noreferrer"
      disabled={disabled}
    >
      {id}
    </Link>
    <CopyButton text={id} />
  </div>
);

const AccountName = ({
  name,
  disabled,
}: {
  name: string;
  disabled?: boolean;
}) => (
  <div className="flex items-center gap-2">
    <div
      className={clsx("text-sm", {
        "text-default": !disabled,
        "text-subdued": disabled,
      })}
    >
      {name}
    </div>
    {disabled && <div className="text-medium text-2xs">DISABLED</div>}
  </div>
);

const CompanyName = ({
  customerId,
  name,
  disabled,
}: {
  customerId: number;
  name: string;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(generatePath(PATHS.CUSTOMER_DETAILS, { customerId }));
  };
  return (
    <div
      className={clsx("text-sm", {
        "text-default": !disabled,
        "text-subdued": disabled,
      })}
    >
      <Link onClick={handleClick}>{name}</Link>
    </div>
  );
};

const AwsOrgAccountTypeBadge = ({
  payerType, // todo: add aws org account type field to backend
  masterAccountId,
}: {
  payerType: string;
  masterAccountId: string;
}) => {
  if (["DEDICATED", "SHARED"].includes(payerType)) {
    return <Badge size="small" status="active" label="Payer Account" />;
  }
  if (masterAccountId) {
    return <Badge size="small" label="Member Account" />;
  }
  return <Badge size="small" label="Unaffiliated" strong />;
};

const HEADERS: TableHeader[] = [
  {
    accessor: "id",
    label: "ID",
    sort: "ID",
    width: "20%",
  },
  {
    accessor: "name",
    label: "Account Name",
    sort: "NAME",
    width: "30%",
  },
  {
    accessor: "company",
    label: "Company",
    sort: "COMPANY__NAME",
    width: "30%",
  },
  {
    accessor: "org",
    label: "AWS Org.", // todo: add sort by aws org account type
    width: "20%",
  },
  {
    accessor: "actions",
    label: " ",
  },
];

const DEFAULT_SORT = "COMPANY__NAME_ASC";

const STATUS_FILTER_OPTIONS = [
  { id: "active", label: "Active" },
  { id: "disabled", label: "Disabled" },
];
