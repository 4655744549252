import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import { CompanySortEnum } from "@m/api/v4/types";
import { toast } from "@m/ui";

export const GET_COMPANIES = gql(/* GraphQL */ `
  query GetCompanies($sort: [CompanySortEnum]) {
    companies(active: true, sort: $sort) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`);

export const useCompanies = () => {
  const { data, ...result } = useQuery(GET_COMPANIES, {
    variables: {
      sort: [CompanySortEnum.NameAsc],
    },
    fetchPolicy: "cache-and-network",
    onError: () => toast.error("Unable to fetch companies"),
  });

  const companies = data?.companies.edges.map((edge) => edge.node) ?? [];

  return {
    data: {
      companies,
    },
    ...result,
  };
};
