import { useLazyQuery } from "@apollo/client";

import { gql } from "@m/api/v4";

export const CAN_REGISTER_AWS_ACCOUNT_ID = gql(/* GraphQL */ `
  query useCanRegisterAwsAccountId($id: String!, $companyId: ID!) {
    canRegisterAwsAccountId(id: $id, companyId: $companyId)
  }
`);

export const useCanRegisterAwsAccountId = () => {
  const [fetchQuery, { data, refetch, called, ...result }] = useLazyQuery(
    CAN_REGISTER_AWS_ACCOUNT_ID,
    { notifyOnNetworkStatusChange: true }
  );

  const validateAccountId = (id: string, companyId: string) => {
    if (called) refetch({ id, companyId });
    else fetchQuery({ variables: { id, companyId } });
  };

  const canBeRegistered = data?.canRegisterAwsAccountId;

  return [
    validateAccountId,
    { data: canBeRegistered, called, ...result },
  ] as const;
};
