import clsx from "clsx";
import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  Badge,
  FilterBar,
  FilterSelect,
  FilterToggle,
  Search,
  Table,
  createFilterOptions,
  useSearchTerm,
} from "@m/ui";

import { PATHS } from "@atlas/constants";

import { useCustomerFilters, useCustomerList } from "../api";
import {
  DEFAULT_SORT,
  MAX_SHOWN_ENTITLEMENTS,
  NO_CUSTOMERS_FOUND,
  SEARCH_PLACEHOLDER,
  TABLE_HEADERS,
} from "../constants";

export const CustomersTable = () => {
  const navigate = useNavigate();

  const [query, setQuery] = useQueryParams({
    active: withDefault(BooleanParam, true),
    entitlements: withDefault(ArrayParam, []),
    sort: withDefault(StringParam, DEFAULT_SORT),
    search: withDefault(StringParam, ""),
    marketplace: withDefault(BooleanParam, false),
  });

  const {
    data: {
      filters: { entitlements },
    },
  } = useCustomerFilters();

  const {
    data: { customers },
    loading,
    pagination,
  } = useCustomerList(query);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ query, setQuery });

  const handleToggleStatusFilter = () => {
    setQuery({ active: !query.active });
  };

  const handleToggleMarketplaceFilter = () => {
    setQuery({
      marketplace: !query.marketplace,
    });
  };

  const handleSelectEntitlementFilters = (entitlements: Array<string>) => {
    setQuery({ entitlements });
  };

  const handleClearEntitlementFilters = () => {
    setQuery({ entitlements: [] });
  };

  const handleResetFilters = () => {
    clearSearchTerm();
    setQuery({
      entitlements: [],
      active: true,
      search: "",
      marketplace: false,
    });
  };

  const hasActiveFilters =
    !query.active ||
    query.entitlements.length > 0 ||
    query.search !== "" ||
    query.marketplace;

  const getBadges = useMemo(
    () =>
      (
        customer: (typeof customers)[number],
        entitlements: (string | null)[]
      ) => {
        if (!entitlements) return [];

        const badges = [...entitlements].sort((a, b) =>
          String(a).localeCompare(String(b))
        );

        if (customer?.hasActiveMarketplaceRegistration) {
          if (badges.length >= MAX_SHOWN_ENTITLEMENTS) {
            badges.splice(
              MAX_SHOWN_ENTITLEMENTS - 1,
              0,
              "activeMarketplaceRegistration"
            );
          } else {
            badges.push("activeMarketplaceRegistration");
          }
        }
        return badges;
      },
    []
  );

  const rows = useMemo(
    () =>
      customers.map((customer) => {
        const entitlements = customer?.entitlements || [];
        const isActive = customer?.status === "Active";
        const hiddenEntitlementCount =
          entitlements.length > MAX_SHOWN_ENTITLEMENTS
            ? entitlements.length % MAX_SHOWN_ENTITLEMENTS
            : 0;
        const badges = getBadges(customer, entitlements);

        const handleClick = () =>
          navigate(
            generatePath(PATHS.CUSTOMER_DETAILS, {
              customerId: customer?.databaseId,
            })
          );

        return {
          mcn: (
            <div
              className={clsx("cursor-pointer", { "!text-accent": !isActive })}
              onClick={handleClick}
              role="button"
            >
              {customer?.mcn}
            </div>
          ),
          name: (
            <div
              aria-label="Customer Name"
              className={clsx(
                "!flex cursor-pointer !items-center !space-x-2 !text-sm",
                {
                  "!font-bold !text-default": isActive,
                  "!text-accent": !isActive,
                }
              )}
            >
              <div>{customer?.name}</div>
              {!isActive && <div className="!text-2xs">INACTIVE</div>}
            </div>
          ),
          entitlements: (
            <div className="flex justify-end space-x-1">
              {badges.slice(0, MAX_SHOWN_ENTITLEMENTS).map((type) => {
                if (type === "activeMarketplaceRegistration")
                  return (
                    <Badge
                      key={type}
                      label={"Marketplace"}
                      size="small"
                      status="highlight"
                      className={clsx({ "bg-white !text-accent": !isActive })}
                    />
                  );
                return (
                  <Badge
                    key={type}
                    label={type || ""}
                    size="small"
                    status="active"
                    className={clsx({ "bg-white !text-accent": !isActive })}
                  />
                );
              })}
              {hiddenEntitlementCount > 0 && (
                <div
                  className={clsx("text-xs font-semibold", {
                    "text-action": isActive,
                    "text-accent": !isActive,
                  })}
                >
                  +{hiddenEntitlementCount} more
                </div>
              )}
            </div>
          ),
          onClick: handleClick,
        };
      }),
    [customers, navigate, getBadges]
  );

  return (
    <div>
      <FilterBar
        isActive={hasActiveFilters}
        onResetFilters={handleResetFilters}
        className="mb-2"
      >
        <Search
          placeholder={SEARCH_PLACEHOLDER}
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
        <FilterToggle
          ariaLabel="Status Filter"
          active={query.active}
          disabled={loading}
          label="Active"
          onClick={handleToggleStatusFilter}
        />
        <FilterSelect
          ariaLabel="Products Filter"
          disabled={loading}
          multiple
          initialValue="Products"
          onChange={handleSelectEntitlementFilters}
          onClear={handleClearEntitlementFilters}
          options={createFilterOptions(entitlements)}
          selection={query.entitlements || []}
        />
        <FilterToggle
          ariaLabel="Marketplace Filter"
          active={query.marketplace}
          disabled={loading}
          label="Marketplace"
          onClick={handleToggleMarketplaceFilter}
        />
      </FilterBar>
      <Table
        emptyMessage={NO_CUSTOMERS_FOUND}
        loading={loading}
        headers={TABLE_HEADERS}
        rows={rows}
        showHeader={false}
        {...pagination}
      />
    </div>
  );
};
