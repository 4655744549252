import { CheckIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";

import { Button, Field, Fields, Input, Modal, Select, Suspensed } from "@m/ui";

import {
  useAddAwsAccount,
  useCanRegisterAwsAccountId,
  useCompanies,
} from "../api";

interface AddAwsAccountModalProps {
  onClose: () => void;
}

export const AddAwsAccountModal = ({ onClose }: AddAwsAccountModalProps) => {
  const {
    data: { companies },
    loading: companiesLoading,
  } = useCompanies();

  const [validateAccountId, { data: canBeRegistered }] =
    useCanRegisterAwsAccountId();

  const [
    addAwsAccount,
    { data: awsAccountAdded, loading: addAwsAccountLoading },
  ] = useAddAwsAccount();

  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [accountId, setAccountId] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");

  const [accountIdError, setAccountIdError] = useState<string>("");

  const handleChangeSelectedCompany = ({
    target: { value: companyId },
  }: ChangeEvent<HTMLSelectElement>) => {
    setAccountIdError("");
    setSelectedCompanyId(companyId);
    if (accountId.length === 12 && companyId)
      validateAccountId(accountId, companyId);
  };

  const handleChangeAccountId = ({
    target: { value: accountId },
  }: ChangeEvent<HTMLInputElement>) => {
    setAccountIdError("");
    if (isNaN(Number(accountId))) return;

    setAccountId(accountId);
    if (accountId.length === 12 && selectedCompanyId)
      validateAccountId(accountId, selectedCompanyId);
  };

  const handleSubmitAddAccount = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addAwsAccount({
      companyId: selectedCompanyId,
      id: accountId,
      name: accountName,
    });
  };

  useEffect(
    function handleAwsAccountAdded() {
      if (awsAccountAdded) onClose();
    },
    [awsAccountAdded, onClose]
  );

  useEffect(
    function handleCanNotRegisterAccountId() {
      if (canBeRegistered === false)
        setAccountIdError("Unable to register this AWS account.");
    },
    [canBeRegistered]
  );

  const companyOptions = useMemo(
    () =>
      companies.map((company) => (
        <option key={company.id} value={company.id}>
          {company.name}
        </option>
      )),
    [companies]
  );

  const isAccountIdValid = accountId.length === 12 && canBeRegistered;
  const isAddAccountDisabled = !selectedCompanyId || !isAccountIdValid;

  return (
    <Modal
      header={<Modal.Title>Add Account</Modal.Title>}
      onClose={onClose}
      open
    >
      <form onSubmit={handleSubmitAddAccount}>
        <Fields className="my-2.5">
          <Field label="Company Name" htmlFor="company-name">
            <Suspensed loading={companiesLoading} height="2.5rem">
              <Select
                id="company-name"
                onChange={handleChangeSelectedCompany}
                options={companyOptions}
                placeholder="Select Company"
                required
                value={selectedCompanyId}
              />
            </Suspensed>
          </Field>
          <Field label="Account ID" htmlFor="account-id" error={accountIdError}>
            <Input
              id="account-id"
              maxLength={12}
              minLength={12}
              onChange={handleChangeAccountId}
              pattern="^\d{12}$"
              placeholder="e.g. 123456789245"
              required
              rightIcon={isAccountIdValid && GreenCheckIcon}
              title="Enter a valid AWS account ID."
              type="text"
              value={accountId}
            />
          </Field>
          <Field label="Account Name" htmlFor="account-name" flag="optional">
            <Input
              id="account-name"
              placeholder="e.g. ABC Accs"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </Field>
        </Fields>

        <Modal.Actions className="flex justify-between">
          <Button fill="none" onClick={onClose}>
            Cancel
          </Button>
          <Button
            kind="primary"
            type="submit"
            loading={addAwsAccountLoading}
            disabled={isAddAccountDisabled}
          >
            Add Account
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};

const GreenCheckIcon = () => (
  <CheckIcon className="h-2.5 w-2.5 text-status-good" />
);
